import { useState } from 'react';
import Lightbox from "yet-another-react-lightbox";

export const PhotoGallery = ({folder, list, filetype}) => {
    const [index, setIndex] = useState(-1);

    const slides = list.map((item, index) => {
        return {src: `${folder}${item}${filetype}`}
    })

    return (
        <>
            <div className='gallery-grid'>
                {
                    list.map((item, index) => {
                        return(
                            <img className='gallery-img' onClick={() => setIndex(index)} src={`${folder}${item}${filetype}`} alt="" 
                            style={{objectFit: 'cover', objectPosition: 'top center', cursor: 'pointer'}}/>
                        )
                })}
            </div>
            <Lightbox
                open={index >= 0}
                index={index}
                close={() => setIndex(-1)}
                slides={slides}
                animation={{swipe: 250}}
            />
        </>
    )
}