import React from 'react'

export default function AreasOfStatistics() {
    return (
        <>
        <p className="boxes-intro">The following is a rough subdivision of the field of statistics, for the purpose of the prize.
        It is intended to ensure diversity of award topics and as a guide for the composition of the jury.</p>
        <br/><br/>
            <div className="boxes-text boxes-text-dark">
                <div>
                    <h3>I. General statistical methodology</h3>
                    <p>Topics include: estimation, inference, model selection, multivariate
                        statistics, nonparametric methods, prediction.</p>
                </div>

                <div>
                    <h3>II. Computational statistics and data science</h3>
                    <p>Topics include: algorithms, big data, classification, clustering, data analysis, data mining,
                        data science, data visualization, image analysis, high-dimensional data, machine learning,
                        statistical computing, statistical learning, statistical languages and software, statistical
                        methods of pattern recognition.</p>
                </div>

                <div>
                    <h3>III. Biostatistics</h3>
                    <p>Topics include: bioinformatics, biometrics, clinical trials, epidemiology,
                        genomic statistics, GLM, medical statistics, multiple testing, pharmaceutical statistics,
                        survival analysis.</p>
                </div>

                <div>
                    <h3>IV. Statistics in the physical sciences and industry</h3>
                    <p>Topics include: astrostatistics, calibration, chemometrics, design of experiments, environmetrics, forensic
                        statistics, geostatistics, quality assurance, reliability, spatial statistics, statistics in agriculture, statistical process
                        control.</p>
                </div>

                <div>
                    <h3>V. Statistics in economics and humanities</h3>
                    <p>Topics include: business statistics, data collection, demography, econometrics, financial
                        statistics, forecasting, official statistics, panel data, psychometrics, sociometrics.</p>
                </div>
            </div>
        </>
    )
}
