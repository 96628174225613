import React from "react";
import ReactDOM from "react-dom";
import Footer from "./Footer";
import Header from "./Header";
import "./index.scss";
import Home from "./pages/Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import About from "./pages/About";
import Nominations from "./pages/Nominations";
import ScrollToTopOnLocationChange from "./helpers/ScrollToTopOnLocationChange";
import ScrollToTop from "react-scroll-up";
import Ceremony from "./pages/Ceremony";
import News from "./pages/News";
import Contact from "./pages/Contact";
import axios from "axios";
import Winners2022 from "./content/News/Winners2022";
import Winners2024 from "./content/News/Winners2024";
require("dotenv").config();

let uri;
if (process.env.NODE_ENV === "production") {
  uri = `https://rousseeuw-server.herokuapp.com/countVisit`;
} else {
  uri = `http://localhost:5000/countVisit`;
}

if (!sessionStorage.getItem("played-intro")) {
  axios.post(uri);
}

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ScrollToTopOnLocationChange />
      <Header />
      <ScrollToTop showUnder={1200}>
        <div className="scroll-to-top">
          <p>top</p>
        </div>
      </ScrollToTop>
      <div className="page">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/nominations" element={<Nominations />} />
          <Route path="/ceremony" element={<Ceremony />} />
          <Route path="/news" element={<News />} />
          <Route path="/contact" element={<Contact />} />

          <Route path="/news/winners-2022" element={<Winners2022 />} />
          <Route path="/news/winners-2024" element={<Winners2024 />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
