var validator = require("email-validator");

export default function validateForm(captcha, contributors) {
    let errors = []
    
    if(document.getElementById('title').value === '') errors.push("Title of the statistical innovation")

    for(let i = 0; i < contributors.length; i++){
        let emptyEmail = false
        if(document.getElementById(`name-${i}`).value === '') errors.push(`Nominee ${i + 1}: Name`)
        if(document.getElementById(`affiliation-${i}`).value === '') errors.push(`Nominee ${i + 1}: Affiliation`)
        let email = document.getElementById(`email-${i}`).value
        if(email === ''){
            errors.push(`Nominee ${i + 1}: Email`)
            emptyEmail = true
        }
        if(!validator.validate(email) && !emptyEmail) errors.push(`Nominee ${i + 1}: Invalid Email`)
        if(document.getElementById(`cv-${i}`).value === '') errors.push(`Nominee ${i + 1}: Curriculum Vitae`)
    }

    if(document.getElementById('nominator-name').value === '') errors.push("Nominator: Name")
    if(document.getElementById('nominator-affiliation').value === '') errors.push("Nominator: Affiliation")

    let emptyNominatorEmail = false
    let nominatorEmail = document.getElementById('nominator-email').value
    if(nominatorEmail === ''){
        errors.push("Nominator: Email")
        emptyNominatorEmail = true
    }
    if(!validator.validate(nominatorEmail) && !emptyNominatorEmail) errors.push("Nominator: Invalid Email")
    if(document.getElementById('cv-nominator').value === '') errors.push("Nominator: Curriculum Vitae")
    if(document.getElementById('motivate-innovation').value === '') errors.push("Motivate why this contribution can be considered as pioneering work in statistical methodology. Describe why it is innovative")
    if(document.getElementById('motivate-research').value === '') errors.push("Describe its impact on statistical research")
    if(document.getElementById('motivate-practice').value === '') errors.push("Describe its impact on statistical practice and society")
    if(document.getElementById('motivate-software').value === '') errors.push("List its availability in (statistical) software")
    if(document.getElementById('file-reference').value === '') errors.push("Reference letter")
    if(document.getElementById('file-letter-1').value === '') errors.push("Letter of recommendation")
    if(document.getElementById('file-letter-2').value === '') errors.push("Letter of recommendation")
    if(document.getElementById('checkbox-decision').checked === false) errors.push("Agree that the decision of KBF is final, and afterward no discussion about it is possible")
    if(document.getElementById('checkbox-nominees').checked === false) errors.push("Confirm that the nominees have been contacted and they agree with the nomination")
    if(document.getElementById('checkbox-confirm').checked === false) errors.push("Confirm that the nomination is complete, and cannot be updated")

    if(captcha === '') errors.push('Invalid captcha')

    return errors
}
