import './Contact.scss'
import Facebook from '../assets/img/facebook.svg'
import LinkedIn from '../assets/img/linkedin.svg'
import Twitter from '../assets/img/x.svg'

function Contact(){
    return(
        <div id="contact">
            <div className="content">
                <h3>Organizers</h3>
                <p>Prof. Mia Hubert and Prof. Stefan Van Aelst</p>
                <p>KU Leuven - Department of Mathematics</p>
                <p><a href="https://wis.kuleuven.be/statdatascience/robust" target="_blank" rel="noreferrer">Section of Statistics and Data Science</a></p>
                
                <h3>Email</h3>
                <p><a href="mailto:mia.hubert@kuleuven.be">mia.hubert@kuleuven.be</a></p>
                <p><a href="mailto:stefan.vanaelst@kuleuven.be">stefan.vanaelst@kuleuven.be</a></p>
                <p><a href="mailto:organizers@rousseeuwprize.org">organizers@rousseeuwprize.org</a></p>

                <br/><br/>
                <div id="socials">
                    <h3>Stay up to Date</h3>
                    <div>
                    <a href="https://www.linkedin.com/company/rousseeuwprize/" target="_blank" rel="noreferrer"><img src={LinkedIn} alt="" /></a>
                    <a href="https://www.facebook.com/RousseeuwPrize/" target="_blank" rel="noreferrer"><img src={Facebook} alt="" /></a>
                    <a href="https://twitter.com/RousseeuwPrize" target="_blank" rel="noreferrer"><img className='social-icon' src={Twitter} alt="" /></a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact