import React from "react";
import "../../pages/News.scss";
import Laureates from "../../assets/img/news/RPrize_Laureates_2022.png";
import PressGerman from "../../assets/files/2022/20220620_DE_Persbericht_Rousseeuw_Prize_DE_PR.docx";
import PressDutch from "../../assets/files/2022/20220620_NL_Persbericht_Rousseeuw_Prize_KB_PR.docx";
import PressFrench from "../../assets/files/2022/20220620_FR_Persbericht_Rousseeuw_Prize.docx";
import LongDescription from "../../assets/files/2022/Long_description_for_statisticians_20220618.pdf";
import ShortDescription from "../../assets/files/2022/Short_description_for_statisticians_20220618.pdf";
import { Link } from "react-router-dom";

export default function Winners2022() {
  return (
    <div id="news-page">
      <div className="content">
        <h1>2022 Rousseeuw Prize awarded to Causal Inference</h1>
        <div id="date">
          <h3>20 June 2022</h3>
        </div>
        <h2>
          The{" "}
          <a
            href="https://www.kbs-frb.be/en/one-million-dollars-statistical-research"
            rel="noreferrer"
            target="_blank"
          >
            King Baudouin Foundation
          </a>{" "}
          has awarded the 2022 Rousseeuw Prize for Statistics to James Robins,
          Miguel Hernán, Thomas Richardson, Andrea Rotnitzky, and Eric Tchetgen
          Tchetgen for their pioneering work on{" "}
          <i>
            Causal Inference with applications in Medicine and Public Health
          </i>
          .
        </h2>
        <img
          id="cover-img"
          src={Laureates}
          alt="Laureates from the 2022 Rousseeuw Prize for Statistics"
        />
        <p id="cover-description">
          Top row left to right: James Robins, Andrea Rotnitzky, Miguel Hernán
          <br />
          Bottom row: Thomas Richardson, Eric Tchetgen Tchetgen
        </p>
        <p style={{ marginTop: "50px" }}>
          Half the prize amount will go to James Robins of Harvard University
          and half will be shared by Miguel Hernán (Harvard University), Thomas
          Richardson (University of Washington), Andrea Rotnitzky (Universidad
          Torcuato di Tella, Argentina) and Eric Tchetgen Tchetgen (University
          of Pennsylvania). The latter four laureates were either trained or
          deeply influenced by Robins. They remain his principal collaborators
          to this day.
          <h3>The link between jellyfish stings and ice cream</h3>
          The team is recognised for pioneering research on causal inference
          with applications in medicine and public health. Causal inference is
          the process of determining causes and effects. This can be harder than
          it looks, because several factors can occur together. Take for example
          the number of jellyfish stings suffered on a given day. This tends to
          move in line with sales of ice cream. That is known as correlation: on
          the days with higher ice cream consumption there tend to be more
          jellyfish stings. The number of jellyfish stings is also correlated
          with the air temperature, the number of people swimming in the sea,
          the use of air-conditioning and so on. But these factors are not all
          causes of jellyfish stings, since eating an ice cream doesn’t give you
          a jellyfish sting. Swimming in the sea can.
          <h3>Revolution in statistics</h3>
          The laureates’ work has provided new insights and statistical methods
          for addressing central epidemiological questions. For example, what is
          the effect of a long-term medical treatment? And, if beneficial, what
          treatment strategies are optimal? The laureates’ work has for instance
          resulted in guidelines on when to best initiate antiviral therapy in
          people with HIV.
          <br />
          Causal questions like these above are complex to address. Robins
          showed that it was especially difficult to interpret data from studies
          that measured exposures or treatments repeated over time. This is
          because there can be feedback mechanisms. In people with HIV, for
          instance, doctors start antiviral treatment in those whose immunity
          has been compromised by the virus. Therefore, patients who start
          antiviral treatment are on average sicker than patients who do not
          start treatment. This could give the unfortunate erroneous impression
          that the treatment is harmful. Then, imagine the treatment does
          improve a patient’s immune response. This results in a complex
          feedback system, where immunity affects the treatment the patient
          receives, which in turn affects future immunity and thus future
          treatment. Robins solved this methodological problem in a series of
          ingenious papers, published in the 1980s. He thereby laid the
          foundations for a long line of methodological innovations by the
          laureates, which has helped launch a causal revolution in statistics.
          <h3>Impact from epidemic to economy</h3>
          The laureates’ work has had a huge influence on statistical practice
          in medicine and public health. It has also spread to other fields,
          such as economics and psychology. In a number of important cases, it
          has demonstrated that disparities between conclusions drawn from
          experimental and non-experimental studies are due to the use of older
          statistical methods that were not suitable. Examples which have had
          both scientific and societal impact include studies of:
          <ul>
            <li>post-menopausal hormone therapy on coronary heart disease</li>
            <li>statin therapy on cancer</li>
            <li>
              the benefits of anti-inflammatory therapy for Covid-19 patients.
            </li>
          </ul>
          The work honoured by the Rousseeuw Prize has completely transformed
          the way in which statisticians, epidemiologists, and others infer the
          effects of interventions, treatments, and exposures to potentially
          harmful substances. It has greatly improved the overall reliability of
          causal analysis in medicine and public health, with great benefit to
          society.
          <br />
          <br />
          The prize will be awarded at a <Link to="/ceremony">
            ceremony
          </Link>{" "}
          taking place at KU Leuven on Wednesday October 12 2022.
        </p>

        <hr></hr>

        <div className="dark-background">
          <h3>Jury 2022</h3>
          <p>David Hand (chair) - Imperial College</p>
          <br />
          <p>Lutgarde Buydens - Radboud University Nijmegen</p>
          <p>Probal Chaudhuri - Indian Statistical Institute</p>
          <p>Dianne Cook - Monash University</p>
          <p>Roger Koenker - University of Illinois</p>
          <p>Yanyuan Ma - Penn State</p>
          <p>David Scott - Rice University</p>
          <p>David Steinberg - Tel Aviv University</p>
          <p>Jane-Ling Wang - UC Davis</p>
          <p>James Zidek - University of British Columbia</p>
        </div>

        <hr></hr>

        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <p>
            <a href={PressDutch} target="_blank" rel="noreferrer" download>
              Dutch press release
            </a>
          </p>
          <p>
            <a href={PressFrench} target="_blank" rel="noreferrer" download>
              French press release
            </a>
          </p>
          <p>
            <a href={PressGerman} target="_blank" rel="noreferrer" download>
              German press release
            </a>
          </p>
          <p>
            <a href={LongDescription} target="_blank" rel="noreferrer" download>
              Long technical description for statisticians
            </a>
          </p>
          <p>
            <a
              href={ShortDescription}
              target="_blank"
              rel="noreferrer"
              download
            >
              Short technical description for statisticians
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
