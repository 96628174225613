import React from 'react'

export default function TheKBF() {
    return (
        <p>The Rousseeuw Prize is awarded by the <a href="https://www.kbs-frb.be/en/about-us" target="_blank" rel="noreferrer">King Baudouin Foundation</a> (KBF),
            a large public utility foundation in Belgium. As the home institution of the prize, KBF is the only entity legally and
            financially responsible for the decision on the award, and for handing out the prize money.
            To guide its decisions KBF considers recommendations made by a jury, which it is under no obligation to follow.
            The decision of KBF is final, and afterward no discussion about the selection is possible.</p>
    )
}
