import React from 'react'
import Ceremony2022 from '../content/Ceremony/Ceremony2022'
import './Ceremony.scss'

export default function Ceremony() {
    return (
        <div id="ceremony">
            <div className="content">
                <Ceremony2022/>
            </div>
        </div>
    )
}
