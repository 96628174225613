import React, { useRef, useState } from "react";
import "./Nominations.scss";
import { AiFillPlusCircle } from "react-icons/ai";
import { AiFillMinusCircle } from "react-icons/ai";
import validateForm from "../helpers/validateForm";
import generateID from "../helpers/generateID";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import parse from "html-react-parser";

export default function Nominations() {
  const [contributors, setContributors] = useState([{ data: 1 }]);
  const [errors, setErrors] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [serverErrorMsg, setServerErrorMsg] = useState("");
  const [progress, setProgress] = useState(0);

  const captchaRef = useRef();

  const addContributor = () => {
    setContributors([...contributors, { data: contributors.length + 1 }]);
  };

  const removeContributor = (index) => {
    if (index === 0) return;

    confirmAlert({
      title: "",
      message: `Are you sure you want to remove nominee ${index + 1}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            let copy = [...contributors];
            copy.splice(index, 1);
            setContributors(copy);
          },
        },
        {
          label: "Cancel",
          onClick: () => {
            return;
          },
        },
      ],
    });
  };

  const submitForm = () => {
    let errors = validateForm(captchaRef.current.getValue(), contributors);
    setSuccess(false);
    setServerErrorMsg("");
    if (errors.length > 0) {
      setErrors(errors);
    } else {
      //submit form
      setErrors([]);
      let formData = new FormData();
      formData.append("id", generateID());
      formData.append("title", document.getElementById("title").value);

      let contributorData = [];
      for (let i = 0; i < contributors.length; i++) {
        contributorData.push({
          id: i + 1,
          name: document.getElementById(`name-${i}`).value,
          affiliation: document.getElementById(`affiliation-${i}`).value,
          email: document.getElementById(`email-${i}`).value,
        });
        formData.append(
          `cv-contributor-${i + 1}`,
          document.getElementById(`cv-${i}`).files[0]
        );
      }
      formData.append("contributors", JSON.stringify(contributorData));

      let nominator = {
        name: document.getElementById("nominator-name").value,
        affiliation: document.getElementById("nominator-affiliation").value,
        email: document.getElementById("nominator-email").value,
      };
      formData.append("nominator", JSON.stringify(nominator));
      formData.append(
        "cv-nominator",
        document.getElementById("cv-nominator").files[0]
      );

      formData.append("category", document.getElementById("category").value);
      formData.append(
        "motivateInnovation",
        document.getElementById("motivate-innovation").value
      );
      formData.append(
        "motivateResearch",
        document.getElementById("motivate-research").value
      );
      formData.append(
        "motivatePractice",
        document.getElementById("motivate-practice").value
      );
      formData.append(
        "motivateSoftware",
        document.getElementById("motivate-software").value
      );
      formData.append(
        "referenceList",
        document.getElementById("file-reference").files[0]
      );
      formData.append(
        "recomLetter1",
        document.getElementById(`file-letter-1`).files[0]
      );
      formData.append(
        "recomLetter2",
        document.getElementById(`file-letter-2`).files[0]
      );

      setUploading(true);

      let uri;
      if (process.env.NODE_ENV === "production") {
        uri = `https://rousseeuw-server.herokuapp.com/submitForm`;
      } else {
        uri = `http://localhost:5000/submitForm`;
      }

      axios({
        method: "post",
        url: uri,
        data: formData,
        headers: {
          "Content-Type": "x-www-form-urlencoded",
        },
      })
        .then((res) => {
          if (res.status === 200) {
            setServerErrorMsg("");
            setUploading(false);
            setSuccess(true);
          }
        })
        .catch((error) => {
          setUploading(false);
          setSuccess(false);
          console.log(error);
          if (error.response) {
            setServerErrorMsg(error.response.data);
          } else {
            setServerErrorMsg(
              `<p>Please keep this page open, and check whether you have received a confirmation by email (also check your spam filter) within the next 10 minutes.</p>`
            );
          }
        });
    }
  };

  const countCharacters = (val, field, max) => {
    document.getElementById(`${field}-characters`).innerHTML = `${
      max - val.target.value.length
    } characters remaining`;
  };

  return (
    <div id="nominations">
      <div className="content">
        <div className="title">
          {/* <h1>Nomination Form</h1> */}
          <h2 style={{ fontWeight: "700" }}>
            Nominations for the 2024 award are now closed.
          </h2>
          {/* <p style={{marginTop: '1rem'}}>Nominations can be submitted until February 29th, 2024.</p> */}
        </div>

        {/* <div className="rule-warning">
                    <p>According to the rules and in view of the first award winning topic, the next award can not:</p>
                    <ul>
                        <li><p>be in the subfield of biostatistics</p></li>
                        <li><p>be from mainly the second international region (AU, CA, IR, NZ, UK, USA)</p></li>
                        <li><p>be in Peter Rousseeuw&#39;s research areas</p></li>
                        <li><p>go to people working in Belgium or of Belgian nationality.</p></li>
                    </ul>
                    <p>When in doubt about the admissibility of a potential nomination, please ask <a href="mailto:organizers@rousseeuwprize.org">organizers@rousseeuwprize.org</a></p>
                
                    <p style={{marginTop: '2rem'}}>When making a nomination that was submitted in an earlier round, it is allowed to reuse the previous recommendation letters.</p>
                </div>

                <form>
                    <h3>I. General Information</h3>

                    <label htmlFor="title" className="formtitle">I.i&nbsp;&nbsp;&nbsp;&nbsp;Title of the statistical innovation</label>
                    <input type="text" id="title" onInput={(e) => countCharacters(e, "title", 100)} maxLength={100}/>
                    <p className="char-count" id="title-characters">100 characters remaining</p>

                    <label htmlFor="category" className="mt formtitle">I.ii&nbsp;&nbsp;&nbsp;&nbsp;Specify the most appropriate area or subfield of statistics for this innovation</label>
                    <div className="grey-bg">
                        <div>
                            <h3>Area / Subfield</h3>
                            <select id="category">
                                <option value="general_methodology">General statistical methodology</option>
                                <option value="computational_data_science">Computational statistics and data science</option>
                                <option value="biostatistics_environmetrics">Biostatistics and environmetrics</option>
                                <option value="physical_sciences_industry">Statistics in the physical sciences and industry</option>
                                <option value="economics_humanities">Statistics in economics and humanities</option>
                            </select>
                        </div>
                    </div>

                    <div className="vertical section">
                        <label className="mb formtitle">I.iii&nbsp;&nbsp;&nbsp;&nbsp;Nominees for the award (at most 5, preferably more than 1)</label>
                        {
                            contributors.map((contributor, index) => (
                                <div className="horizontal contributor" key={contributor.data}>
                                    <div className="contributor-number">
                                        <p>{index + 1}</p>
                                        <span></span>
                                    </div>

                                    <div className="vertical">
                                        <label htmlFor={`name-${index}`}>Name</label>
                                        <input type="text" id={`name-${index}`}/>
                                    </div>

                                    <div className="vertical">
                                        <label htmlFor={`affiliation-${index}`}>Affiliation</label>
                                        <input type="text" id={`affiliation-${index}`}/>
                                    </div>

                                    <div className="vertical">
                                        <label htmlFor={`email-${index}`}>Email Address</label>
                                        <input type="text" id={`email-${index}`}/>
                                    </div>

                                    <div className="vertical">
                                        <label>Curriculum Vitae (pdf)</label>
                                        <input type="file" accept=".pdf" id={`cv-${index}`}/>
                                    </div>

                                    <div className="remove-contributor" onClick={() => removeContributor(index)}>
                                        <AiFillMinusCircle
                                            style={{opacity: index === 0 ? '0' : '1', cursor: index === 0 ? 'default !important' : 'pointer'}}/>
                                        <p
                                        style={{opacity: index === 0 ? '0' : '1', cursor: index === 0 ? 'default !important' : 'pointer'}}
                                        >
                                            Remove nominee
                                        </p>
                                    </div>
                                </div>
                            ))
                        }
                        {
                            contributors.length < 5 && (
                                <div className="addContributor" onClick={() => addContributor()}>
                                    <AiFillPlusCircle />
                                    <p>Add a nominee</p>
                                </div>
                            )
                        }
                    </div>

                    <label htmlFor="nominator" className="mb formtitle">I.iv&nbsp;&nbsp;&nbsp;&nbsp;Nominator (cannot be one of these nominees)</label>

                    <div className="horizontal">
                        <div className="vertical">
                            <label htmlFor="nominator-name">Name</label>
                            <input type="text" id="nominator-name"/>
                        </div>

                        <div className="vertical">
                            <label htmlFor="nominator-affiliation">Affiliation</label>
                            <input type="text" id="nominator-affiliation"/>
                        </div>

                        <div className="vertical">
                            <label htmlFor="nominator-email">Email Address</label>
                            <input type="text" id="nominator-email"/>
                        </div>

                        <div className="vertical">
                            <label>Curriculum Vitae (pdf)</label>
                            <input type="file" accept=".pdf" id="cv-nominator"/>
                        </div>
                    </div>

                    <label className="mt formtitle" htmlFor="motivate-innovation">I.v&nbsp;&nbsp;&nbsp;&nbsp;Motivate why this contribution can be considered as pioneering work
                    in statistical methodology. Describe why it is innovative.
                    </label>
                    <textarea id="motivate-innovation" onInput={(e) => countCharacters(e, "motivate-innovation", 3000)} maxLength={3000}/>
                    <p className="char-count" id="motivate-innovation-characters">3000 characters remaining</p>

                    <label className="mt formtitle" htmlFor="motivate-research">I.vi&nbsp;&nbsp;&nbsp;&nbsp;Describe its impact on statistical research.
                    </label>
                    <textarea id="motivate-research" onInput={(e) => countCharacters(e, "motivate-research", 3000)} maxLength={3000}/>
                    <p className="char-count" id="motivate-research-characters">3000 characters remaining</p>

                    <label className="mt formtitle" htmlFor="motivate-practice">I.vii&nbsp;&nbsp;&nbsp;&nbsp;Describe its impact on statistical practice and society.
                    </label>
                    <textarea id="motivate-practice" onInput={(e) => countCharacters(e, "motivate-practice", 3000)} maxLength={3000}/>
                    <p className="char-count" id="motivate-practice-characters">3000 characters remaining</p>

                    <label className="mt formtitle" htmlFor="motivate-software">I.viii&nbsp;&nbsp;&nbsp;&nbsp;List its availability in (statistical) software.
                    </label>
                    <textarea id="motivate-software" onInput={(e) => countCharacters(e, "motivate-software", 1500)} maxLength={1500}/>
                    <p className="char-count" id="motivate-software-characters">1500 characters remaining</p>

                    <h3 className="mt-h" id="docs">II. Documents</h3>
                    <h5>All files must be uploaded in <b>pdf</b> format</h5>

                    <label className="formtitle">II.i&nbsp;&nbsp;&nbsp;&nbsp;Upload the reference list of the motivation, listing not only the original publication(s), but also
                    review paper(s) if available. Do not include pdf files of the papers themselves.</label>
                    <div className="grey-bg">
                        <div>
                            <h3>Reference list</h3>
                            <input type="file" accept=".pdf" id="file-reference"/>
                        </div>
                    </div>

                    <label className="mt formtitle">II.ii&nbsp;&nbsp;&nbsp;&nbsp;Upload two letters of recommendation, of at most 3 pages each.</label>
                    <div className="grey-bg">
                        <div>
                            <h3>Letter one</h3>
                            <input type="file" accept=".pdf" id="file-letter-1"/>
                        </div>
                        <div>
                            <h3>Letter two</h3>
                            <input type="file" accept=".pdf" id="file-letter-2"/>
                        </div>
                    </div>

                    <h3 className="mt-h">III. Submission</h3>

                    <div className="check">
                        <input type="checkbox" id="checkbox-nominees" />
                        <label htmlFor="checkbox-nominees">I have contacted the nominees and they agree with the nomination.</label>
                    </div>
                    
                    <div className="check">
                        <input type="checkbox" id="checkbox-decision" />
                        <label htmlFor="checkbox-decision">I agree that the decision of KBF is final, and
                        afterward no discussion about it is possible.</label>
                    </div>

                    <div className="check">
                        <input type="checkbox" id="checkbox-confirm" />
                        <label htmlFor="checkbox-confirm">I confirm that the nomination is complete, and cannot be updated.</label>
                    </div>

                    <ReCAPTCHA
                        sitekey="6LecRUkdAAAAAOzOSXrjWnxoFNC29ApGV9Wzb-Ba"
                        className="captcha"
                        ref={captchaRef}
                    />

                    {
                        errors.length > 0 && (
                            <div className="errors">
                                <p>Some required fields are missing. Please complete the following:</p>
                                <ul>
                                    {
                                        errors.map((error) => (
                                            <li>{error}</li>
                                        ))
                                    }
                                </ul>
                            </div>
                        )
                    }

                    {
                        uploading && (
                            <div className="uploading">
                                <div className="loader"></div>
                                <p>Uploading...</p>
                            </div> 
                        )
                    }

                    {
                        success && (
                            <div className="success">
                                <p>Nomination successfully submitted! You should receive a confirmation email within 10 minutes. 
                                    If you didn't get one, please check your spam folder. If it's not there either, reach out to <a href="mailto:support@rousseeuwprize.org">support@rousseeuwprize.org</a> to verify if we correctly received the submission.</p>
                            </div>
                        )
                    }

                    {
                        serverErrorMsg !== '' && (
                            <div className="errors">
                                {parse(serverErrorMsg)}
                            </div>
                        )
                    }

                    {
                        !uploading && (
                            <button id="submit" type="button" onClick={() => submitForm()}>Submit nomination</button>
                        )
                    }         

                    <p id="problem">If you encounter any problems with the submission, please contact <a href="mailto:support@rousseeuwprize.org">support@rousseeuwprize.org</a></p>
                
                    <h2 className='mt' style={{fontWeight: '700'}}>Nominations for the 2024 award will open on October 1, 2023.</h2>
                </form> */}
      </div>
    </div>
  );
}
