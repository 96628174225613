import "./Home.scss";
import Logo from "../assets/img/logo.svg";
import Facebook from "../assets/img/facebook.svg";
import LinkedIn from "../assets/img/linkedin.svg";
import Twitter from "../assets/img/x.svg";
import AOS from "aos";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

export default function Home() {
  let location = useLocation();
  const [loc, setLoc] = useState(location.pathname);

  useEffect(() => {
    AOS.init({
      disable: () => {
        if (
          window.innerWidth < 1024 ||
          sessionStorage.getItem("played-intro") ||
          loc !== "/"
        ) {
          return true;
        }
      },
    });
  }, []);

  return (
    <>
      <div className="content">
        <div id="hero-section">
          <h3
            data-aos="fade-right"
            data-aos-duration="1500"
            data-aos-delay="2000"
          >
            Celebrating Research
          </h3>
          <img
            src={Logo}
            alt="Rousseeuw Prize for Statistics"
            data-aos="fade-right"
            data-aos-duration="1500"
            data-aos-delay="1000"
          />
          {/* <div id="read-more" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="2000">
            <p>Read more</p>
            <img src={Arrow} alt="" />
          </div> */}
        </div>
        <div
          id="banner-container"
          data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-delay="2000"
        >
          <Link to="/news/winners-2024" id="banner">
            <p>
              The 2024 prize has been awarded!
              <br />
              <span>Click to read more</span>
            </p>
          </Link>
          {/* <span id="banner">
            <p>Nominations open on October 1, 2023<br/></p>
          </span> */}
        </div>
      </div>
      <div id="statements">
        <div className="content">
          <div>
            <h3>Honoring Innovation</h3>
            <p>
              Recognition of an outstanding contribution or tool that has had
              significant impact and found wide application in statistical
              practice, with relevance to society.
            </p>
          </div>
          <div>
            <h3>Promoting Awareness</h3>
            <p>
              Shining light on the important role and intellectual content of
              statistics and its impact on human endeavors.
            </p>
          </div>
          <div>
            <h3>Recognising Diversity</h3>
            <p>
              Ensuring a wide variety of statistical subfields and geographic
              areas are considered.
            </p>
          </div>
        </div>
      </div>
      <div id="socials">
        <h3>Stay up to Date</h3>
        <div>
          <a
            href="https://www.linkedin.com/company/rousseeuwprize/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={LinkedIn} alt="" />
          </a>
          <a
            href="https://www.facebook.com/RousseeuwPrize/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Facebook} alt="" />
          </a>
          <a
            href="https://twitter.com/RousseeuwPrize"
            target="_blank"
            rel="noreferrer"
          >
            <img className="social-icon" src={Twitter} alt="" />
          </a>
        </div>
      </div>
    </>
  );
}
