import React from 'react'

export default function RoleOfSelectionCommittee() {
    return (
        <p>The King Baudouin Foundation appoints an international jury consisting of 10 reputed statisticians.
        <br></br><br></br>
        The jury will study the nominations and make a
        ranked shortlist of 3 options, in case some
        awardees do not accept the prize or are
        unwilling to be present at the award ceremony.
        The jury is at liberty to add or remove persons
        from the list of nominees for the award.
        In the case of several awardees, the jury also 
        proposes how the award money is divided. It
        may be evenly divided, or based on relative 
        merits of awardees' contributions to the 
        statistical innovation.
        <br /><br />
        To avoid undue pressure on the jury, its members 
        are anonymous while they do their work. 
        <br /><br />
        The selection of the award is aimed to be
        impartial and balanced. The members of the jury may not be related to the people on the shortlist by family ties, past or present life partner,
        PhD advisor-student, or being a co-author in the last 15 years. Jury members cannot submit a nomination.
        <br></br><br></br>
        When selecting the award topic and awardees, the 
        jury takes into account 
        important contributions and contributors
        irrespective of gender, race, sexual orientation, 
        ideology, or religion.</p>
    )
}
