import React from 'react'
import './About.scss'
import RousseeuwImg from '../assets/img/peterrousseeuw.png'
import NatureOfThePrize from '../content/About/NatureOfThePrize'
import OriginOfTheName from '../content/About/OriginOfTheName'
import AreasOfStatistics from '../content/About/AreasOfStatistics'
import RulesOfThePrize from '../content/About/RulesOfThePrize'
import { Link } from 'react-router-dom'
import RoleOfSelectionCommittee from '../content/About/RoleOfSelectionCommittee'
import TheKBF from '../content/About/TheKBF'

export default function About() {
    return (
        <div id="about">

            <div className="content">

                <div className="title">
                    <div className="number">
                        <span></span>
                        <h4>01</h4>
                    </div>
                    <h1>Nature of<br />the prize</h1>
                </div>

                <div className="grid-text">
                    <NatureOfThePrize />
                </div>

            </div>

            <div className="darker-background">

                <div className="content">
                    <div className="title title-right">
                        <div className="number">
                            <h4>02</h4>
                            <span></span>
                        </div>
                        <h1>The King<br />Baudouin Foundation</h1>
                    </div>

                    <div className="grid-text">
                        <TheKBF />
                    </div>

                </div>

            </div>

            <div className="content">

                <div className="title">
                    <div className="number">
                        <span></span>
                        <h4>03</h4>
                    </div>
                    <h1>Origin of<br />the name</h1>
                </div>

                <div className="grid-text">
                    <img src={RousseeuwImg} alt="Peter Rousseeuw" />
                    <OriginOfTheName />
                </div>

            </div>

            <div className="darker-background">
                <div className="content">
                    
                    <div className="title title-right">
                        <div className="number">
                            <h4>04</h4>
                            <span></span>
                        </div>
                        <h1>Role of <br/>the Jury</h1>
                    </div>

                    <div className="grid-text">
                        <RoleOfSelectionCommittee />
                    </div>

                </div>
            </div>

            <div className="content">

                <div className="title">
                    <div className="number">
                        <span></span>
                        <h4>05</h4>
                    </div>
                    <h1>Areas/subfields<br/>of Statistics</h1>
                </div>

                <div>
                    <AreasOfStatistics />
                </div>

            </div>

            <div className="darker-background">
                <div className="content">
                    
                    <div className="title title-right">
                        <div className="number">
                            <h4>06</h4>
                            <span></span>
                        </div>
                        <h1>Rules of<br/>the Prize</h1>
                    </div>

                    <div>
                        <RulesOfThePrize />
                    </div>

                </div>
            </div>

            {/* <div className="content">
                <div className="nominate">
                    <h3>Ready to nominate?</h3>
                    <Link to="/nominations">Fill in the nomination form for the 2022 award by the deadline of March 31st, 2022</Link>
                </div>
            </div> */}

        </div>
    )
}
