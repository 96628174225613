import { PhotoGallery } from '../../components/PhotoGallery'

export default function Ceremony2022(){
    const volgorde = ['0540', '0611', '0618', '0640', '0651', '0566', '0573', '0712', 'BinnenkomstKoning', '0734', '0737', '0749', '0776', '0789', '0793', '0806', '0820', '0823', '0824', '0826', '0831', '0835', 'UitreikingMedailles', '0848', '0904', '0912', '0936', '0960', '0982', '1001', '1021', '1033', '1050', '1045', '1041', '1038', '1054', '1073', '1081', '1089', '1174', '1163', '1133', '1138', '1143', '1097', '1120', '1127', '1185', '1204', '1219', '1234', 'RPrize_Ceremony2022_jury']

    return(
        <>
            <div className='ceremony-banner' style={{backgroundImage: 'url(../assets/img/ceremony/2022/1081.jpg)'}}/>
            <p style={{margin: '3em 0 2.5em'}}>The first ceremony took place on October 12th 2022 at the University of Leuven, Belgium.<br/>
            It was followed on October 13th by Peter Rousseeuw's emeritus ceremony.
            </p>
            <div className="boxes-text boxes-text-dark">
                <div className='program' style={{width: '100%'}}>
                    <h3>Program</h3>
                    <p><span>10:00-10:30</span> Pickup badges and coffee</p>
                    <p><span>10:30-12:30</span> Scientific talks. Chair: Prof. Stijn Vansteelandt, Ghent University</p>
                    <ul>
                        <li><p><span>10:30-11:10</span><i> Prof. Thomas Richardson</i> (University of Washington): Causation, Counterfactuals and Graphs&nbsp;
                        <a href="https://rousseeuwprize.org/static/video/20221012_RPrize_Richardson.mp4" target="_blank" rel="noreferrer">Video</a>&nbsp;
                        <a href="../assets/docs/ceremony/2022/Richardson_presentation_RPrize.pdf" target="_blank" rel="noreferrer">Slides</a></p></li>
                        <li><p><span>11:10-11:50</span><i> Prof. Andrea Rotnitzky</i>  (Universidad Torcuato di Tella): 
                        A glimpse of the subtleties about the estimation of the g-formula and related causal parameters&nbsp;
                        <a href="https://rousseeuwprize.org/static/video/20221012_RPrize_Rotnitzky.mp4" target="_blank" rel="noreferrer">Video</a>&nbsp;
                        <a href="../assets/docs/ceremony/2022/Rotnitzky_presentation_RPrize.pdf" target="_blank" rel="noreferrer">Slides</a></p></li>
                        <li><p><span>11:50-12:30</span><i> Prof. James Robins</i> (Harvard University): Causal Inference from Complex Longitudinal Data: Lessons Learned and Open Problems &nbsp;
                        <a href="https://rousseeuwprize.org/static/video/20221012_RPrize_Robins.mp4" target="_blank" rel="noreferrer">Video</a>&nbsp;
                        <a href="../assets/docs/ceremony/2022/Robins_presentation_RPrize.pdf" target="_blank" rel="noreferrer">Slides</a></p></li>
                    </ul>
                    <p><span>12:30-14:15</span> Break for lunch</p>
                    <p><span>14:15-14:45</span> Pickup badges for entry</p>
                    <p><span>14:45-15:00</span> Welcome by Prof. Stefan Van Aelst and Prof. Mia Hubert <a href="https://rousseeuwprize.org/static/video/20221012_RPrize_Welcome.mp4" target="_blank" rel="noreferrer">Video</a></p>
                    <p><span>15:00-15:45</span><i> Prof. Aad van der Vaart</i> (Delft Institute of Applied Mathematics): Causal inference,  what if, and more, an appreciation. <i>Presentation about the winning contribution</i>.&nbsp;
                    <a href="https://rousseeuwprize.org/static/video/20221012_RPrize_vanderVaart.mp4" target="_blank" rel="noreferrer">Video</a>&nbsp;
                    <a href="../assets/docs/ceremony/2022/vanderVaart_presentation_RPrize.pdf" target="_blank" rel="noreferrer">Slides</a></p>
                    <p><span>15:45-16:00</span> Musical interlude by "Cava strijkkwartet" <a href="https://rousseeuwprize.org/static/video/20221012_RPrize_Music.mp4" target="_blank" rel="noreferrer">Video</a></p>
                    <p><span>16:00-17:30</span> Award ceremony</p>
                    <ul>
                        <li><p>Welcome, and presentation by Prof. Peter Rousseeuw about the goal of the prize&nbsp;
                        <a href="https://rousseeuwprize.org/static/video/20221012_RPrize_Welcome_Rousseeuw.mp4" target="_blank" rel="noreferrer">Video</a>&nbsp;
                        <a href="../assets/docs/ceremony/2022/Rousseeuw_speech_at_award_ceremony.pdf" target="_blank" rel="noreferrer">Slides</a></p></li>
                        <li><p>Gerrit Rauws (King Baudouin Foundation) about the selection procedure&nbsp;
                        <a href="https://rousseeuwprize.org/static/video/20221012_RPrize_Rauws.mp4" target="_blank" rel="noreferrer">Video</a></p></li>
                        <li><p>Presentation about the awarded work and laureates (Prof. David Hand)&nbsp;
                        <a href="https://rousseeuwprize.org/static/video/20221012_RPrize_Hand.mp4" target="_blank" rel="noreferrer">Video</a>&nbsp;
                        <a href="../assets/docs/ceremony/2022/Hand_presentation_RPrize.pdf" target="_blank" rel="noreferrer">Slides</a></p></li>
                        <li><p>Awards handed out by his Majesty King Philippe of Belgium, followed by a word of thanks by Prof. James Robins and a musical interlude by Cava Strijkkwartet&nbsp;
                        <a href="https://rousseeuwprize.org/static/video/20221012_RPrize_Awards_Robins_Music.mp4" target="_blank" rel="noreferrer">Video</a></p></li>
                        <li><p>Word of thanks by the other laureates&nbsp;
                        <a href="https://rousseeuwprize.org/static/video/20221012_RPrize_Thanks.mp4" target="_blank" rel="noreferrer">Video</a></p></li>
                    </ul>
                    <p><span>17:30-18:30</span> Reception</p>
                </div>
            </div>
            <h3>A short summary</h3>
            <video src="https://rousseeuwprize.org/static/video/20221012_Summary.mp4" controls/>
            <p><i>Click on an image to see it in full resolution</i></p>
            <br/>
            <PhotoGallery folder='../assets/img/ceremony/2022/' list={volgorde} filetype=".jpg"/>
            <p style={{marginBottom: '100px'}}><i>Pictures by Filip Van Loock</i></p>
        </>
    )
}