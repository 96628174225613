import React from 'react'
import { Link } from 'react-router-dom'

export default function NatureOfThePrize() {
    return (
        <p>Statistics is a cornerstone of science, 
        health, industry, economics, government and more, and
        benefits society as a whole.<br />
        Nevertheless, research in statistics does 
        not yet receive the same level of recognition as
        in related fields such as mathematics, physics, 
        and computer science.
        <br /><br />
        The Rousseeuw Prize for Statistics is intended 
        to help remedy this gap.<br />
        It awards pioneering work in statistical methodology. 
        The prize recognizes a statistical innovation, that is, an outstanding
        contribution or tool that has had significant impact and found
        wide application in statistical practice, with relevance to society.
        <br /><br />
        The Rousseeuw Prize 
        focuses on the innovation rather
        than on a single individual. This allows to
        recognize several individuals who made
        important contributions to it.
        <br /><br />
        One of the goals of awarding the people who 
        created such an innovation, is to promote awareness 
        of the important role and intellectual content of 
        statistics and its profound impact on human
        endeavors.
        <br /><br />
        The prize is awarded in even years, 
        starting in 2022.<br />
        The award amount is one million US dollars per 
        prize, to be split over awardees if there are 
        several, which it is hoped will typically 
        be the case.<br />
        The first award <Link to="/ceremony">ceremony</Link> took place on
        October 12th 2022 at the University of 
        Leuven, Belgium.
        <br /><br />
        For the purpose of the prize, statistics is 
        defined as

        <div className="quote">
        the science and technology of 
        obtaining useful information from data,
        taking its variability into account.
        </div>

        People often underestimate the
        difficulty of doing this well, due to the 
        ease of doing it poorly.<br />
        Statistical work in the above sense 
        can be found under a variety of flags, such as 
        astrostatistics, big data, 
        biometrics, chemometrics, 
        classification, data analysis, data collection,
        data mining, data science, data visualization, 
        design of experiments, econometrics, 
        environmetrics, genomic statistics, 
        machine learning, multivariate analysis, 
        pattern recognition, psychometrics, 
        quality assurance, quantitative finance,
        regression, sociometrics, 
        statistical computing, 
        statistical learning,
        technometrics, time series analysis, etc.
        <br /><br />
        Statistics as described above often applies 
        methods from a host of important disciplines 
        such as approximation theory, 
        convex programming,
        differential equations, 
        numerical linear algebra, 
        optimization, probability theory, 
        software engineering, and so on. 
        But non-statistical work done in such fields 
        is not covered by the present award.
        <br /><br />
        There is no time window for the work, in the
        sense that it would have to be done in the last
        x years. Likewise, there is no age limit on
        awardees. The awardees must be living persons,
        not organizations. If one of the main 
        contributors is no longer alive, the surviving
        author(s) of the joint work can still be 
        awarded.
        The deceased contributor(s) will of course
        be named explicitly.
        <br /><br />
        Nominations will propose a particular 
        innovation as well as a list of at most five potential awardees who contributed to it. <br />
        When making this list it is encouraged to
        consider gender diversity when applicable.
        Self-nomination is not permitted.
        The nominations, including letters of
        recommendation, are to be 
        submitted on this website, see 
        section <Link to="/nominations">nominations</Link>.</p>
    )
}
