import React from "react";
import "./News.scss";
import AmstatArticle from "../assets/files/20220315_AmStatNews_March_Interview.pdf";
import { Link } from "react-router-dom";

export default function News() {
  return (
    <div id="news">
      <div className="content">
        <div className="news-item">
          <h3>JULY 2024</h3>
          <h1>2024 Rousseeuw Prize awarded to False Discovery Rate</h1>

          <p>
            KBF awarded the second Rousseeuw Prize for Statistics to Yoav
            Benjamini, Daniel Yekutieli, and Ruth Heller for their work on the False Discovery Rate (FDR).
          </p>

          <Link to="/news/winners-2024">Click here to read more</Link>
        </div>
        <div className="news-item">
          <h3>OCTOBER 2022</h3>
          <h1>Awards handed over by his Majesty the King of Belgium</h1>

          <p>
            {" "}
            Photos, videos and slides available{" "}
            <Link to="../Ceremony">here</Link>.
          </p>

          <p>
            Read the{" "}
            <a
              href="https://nieuws.kuleuven.be/en/content/2022/first-rousseeuw-prize-for-statistics-awarded-for-pioneering-research-on-causal-inference"
              target="_blank"
              rel="noreferrer"
            >
              news item
            </a>{" "}
            from the KU Leuven.
          </p>
        </div>
        <div className="news-item">
          <h3>JUNE 2022</h3>
          <h1>2022 Rousseeuw Prize awarded to Causal Inference</h1>

          <p>
            KBF awards the first biennial Rousseeuw Prize for Statistics to
            James Robins and others for their research on causal inference in
            medicine and public health.
          </p>

          <Link to="/news/winners-2022">Click here to read more</Link>
        </div>
        <div className="news-item">
          <h3>MARCH 2022</h3>
          <h1>Short interview with Peter Rousseeuw about the Prize</h1>

          <p>
            Amstat News, the newletter of the American Statistical Association,
            has published a short interview with Peter Rousseeuw.
          </p>

          <a href={AmstatArticle} target="_blank" rel="noreferrer" download>
            Download PDF to read the full article
          </a>
        </div>
      </div>
    </div>
  );
}
