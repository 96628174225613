import React, { useEffect, useState } from 'react'
import Logo from './assets/img/logo.svg'
import Medal from './assets/img/medal.png'
import './Header.scss'
import AOS from 'aos';
import { Link, useLocation } from 'react-router-dom';
import { GiHamburgerMenu } from 'react-icons/gi'
import { IoCloseSharp } from 'react-icons/io5'

export default function Header(){
    let location = useLocation()
    const [loc, setLoc] = useState(location.pathname)
    const [hamburgerOpen, setHamburgerOpen] = useState(false)

    useEffect(() => {
        AOS.init({
            disable: () => {
              if(window.innerWidth < 1024 || sessionStorage.getItem('played-intro') || loc !== '/'){
                return true
              }
            }
          })
        setTimeout(() => {
            sessionStorage.setItem('played-intro', true)
        }, 5000)
    }, [])

    useEffect(() => {
        setLoc(location.pathname)
        setHamburgerOpen(false)
    }, [location])

    useEffect(() => {
        if(hamburgerOpen){
            document.body.style.position = "fixed";
        } else{
            document.body.style.position = "initial";
        }
    }, [hamburgerOpen])

    return(
        <>
            <div id="header" 
                style={{
                    borderBottom: loc === '/' ? '' : '1px solid #bb8a3580',
                    overflow: loc === '/' ? 'visible' : 'hidden'
                }}
            >
                <div id="header-inner" style={{justifyContent: loc === '/' ? 'flex-end' : 'space-between'}}>
                    {
                        loc !== '/' &&
                        <Link to="/"><img src={Logo} alt="" /></Link>
                    }
                    <nav data-aos="fade-down" data-aos-duration="1500" data-aos-delay="2000">
                        <ul>
                            <Link to="/" className={loc === '/' ? 'active-link' : ''}><li>Home</li></Link>
                            <Link to="/about" className={loc === '/about' ? 'active-link' : ''}><li>About</li></Link>
                            <Link to="/nominations" className={loc === '/nominations' ? 'active-link' : ''}><li>Nominations</li></Link>
                            <Link to="/ceremony" className={loc === '/ceremony' ? 'active-link' : ''}><li>Ceremony</li></Link>
                            <Link to="/news" className={loc === '/news' ? 'active-link' : ''}><li>News</li></Link>
                            <Link to="/contact" className={loc === '/contact' ? 'active-link' : ''}><li>Contact</li></Link>
                        </ul>
                    </nav>
                </div>
                <img id="background-img" src={Medal} alt="" data-aos="fade-left" data-aos-duration="1500"/>
            </div>

            <div id="header-mobile">
                    <GiHamburgerMenu size={30} onClick={() => setHamburgerOpen(true)}/>
                    <div id="open-hamburger" style={{display: hamburgerOpen ? 'block' : 'none'}}>
                        <IoCloseSharp size={32} onClick={() => setHamburgerOpen(false)}/>
                        <nav>
                            <ul>
                                <Link to="/" className={loc === '/' ? 'active-link' : ''}><li>Home</li></Link>
                                <Link to="/about" className={loc === '/about' ? 'active-link' : ''}><li>About</li></Link>
                                <Link to="/nominations" className={loc === '/nominations' ? 'active-link' : ''}><li>Nominations</li></Link>
                                <Link to="/ceremony" className={loc === '/ceremony' ? 'active-link' : ''}><li>Ceremony</li></Link>
                                <Link to="/news" className={loc === '/news' ? 'active-link' : ''}><li>News</li></Link>
                                <Link to="/contact" className={loc === '/contact' ? 'active-link' : ''}><li>Contact</li></Link>
                            </ul>
                        </nav>
                    </div>
            </div>
        </>
    )
}