import React from "react";
import "../../pages/News.scss";
import Laureates from "../../assets/img/news/Laureates2024.webp";
import PressGerman from "../../assets/files/2024/20240730 - Pressemiiteilung - Zweiter Rousseeuw-Preis für Statistik verliehen-def.pdf";
import PressDutch from "../../assets/files/2024/20240730 - Persbericht - Tweede Rousseeuw Prize for Statistics.pdf";
import PressFrench from "../../assets/files/2024/20240730 - Communiqué de presse - Second Prix Rousseeuw pour des Statistiques.pdf";
import PressEnglish from "../../assets/files/2024/20240730 - Press Release - The second Rousseeuw Prize for Statistics.pdf";
import TechnicalDescription from "../../assets/files/2024/2024_FDR_long_technical_description.pdf";

export default function Winners2024() {
  return (
    <div id="news-page">
      <div className="content">
        <h1>2024 Rousseeuw Prize awarded to False Discovery Rate</h1>
        <div id="date">
          <h3>30 July 2024</h3>
        </div>
        <h2>
          The international and independent jury, appointed by the{" "}
          <a href="https://www.kbs-frb.be/en/" rel="noreferrer" target="_blank">
            King Baudouin Foundation
          </a>
          , has selected the pioneering work on the False Discovery Rate (FDR)
          as the recipient of the biennial Rousseeuw Prize for Statistics 2024.
          This million-dollar prize honours exceptional statistical research
          that profoundly influences society. The inaugural prize in 2022
          celebrated advancements in causal inference. This year’s award focuses
          on <b>the False Discovery Rate (FDR) and Methods to Control It</b>.
          The 1995 paper by Benjamini and Hochberg introduced FDR, providing a
          framework for further expansion and publications. The laureates of the
          prize are Yoav Benjamini, Daniel Yekutieli, and Ruth Heller from Tel
          Aviv University. Yosef Hochberg also deserves much recognition, but
          unfortunately, he is no longer with us. Their research has led to a
          method to limit the number of false discoveries without stifling the
          potential for true discoveries.
        </h2>
        <img
          id="cover-img"
          src={Laureates}
          alt="Laureates from the 2022 Rousseeuw Prize for Statistics"
        />
        <p id="cover-description">
          Left to right: Ruth Heller, Yoav Benjamini, Daniel Yekutieli
        </p>
        <p style={{ marginTop: "50px" }}>
          The acclaimed work is a method that assists scientists in discovering
          genuine findings while maintaining a low number of false discoveries.
          Have you ever read about a promising scientific discovery, only to
          never hear about it again? This is often because subsequent
          experiments by other researchers failed to replicate the effect. This
          is known as the replication crisis in science. A major cause is that
          the original researchers claimed discoveries based on examining a
          large number of results. For instance, when searching for a genetic
          marker, that is, a gene associated with a disease, a laboratory may
          examine over 20,000 genes. But then there is a real danger that an
          apparently promising discovery is not real, but merely due to chance.
          Thus, a method was needed to reduce the number of these false
          discoveries.
          <br />
          <br />
          When you look at many potential results, you have a higher chance of
          false discoveries. An initial approach was to be stricter for each
          individual gene, but then very few discoveries were made. Scientists
          were often faced with two unpleasant choices: either report no
          discoveries at all or publish scientific claims that are on shaky
          ground.
          <br />
          <br />
          In search of a solution, Benjamini and Hochberg realised that the
          ratio of false discoveries to the total number of discoveries could be
          used as a criterion. If a study finds 60 results and among them, there
          are 3 false discoveries, then that is not so bad because the ratio is
          only 5%. But reporting 60 results of which 40 are merely false is not
          acceptable. In 1995, Benjamini and Hochberg published a mathematical
          formulation of the False Discovery Rate (FDR) criterion, as the
          expected ratio of false discoveries to the total number of
          discoveries. They proposed maximising the number of discoveries
          provided that the FDR remains below a certain threshold value. At
          first glance, this seems impossible because we do not know beforehand
          how many false discoveries there are, but they found a way to do it.
          In the Benjamini-Hochberg (BH) procedure, the threshold for a
          discovery automatically adjusts to the information in the data. It
          depends on the data itself whether the final threshold turns out to be
          rather high or low. The paper by Benjamini and Hochberg faced much
          resistance because it differed so greatly from previous methods,
          resulting in five years passing and three journals being approached
          before it finally appeared in 1995. To date, the paper by Benjamini
          and Hochberg has been cited over 100,000 times, a record number.
          <br />
          <br />
          The work surrounding the False Discovery Rate (FDR) was continued by
          Benjamini along with the other laureates, Yekutieli and Heller.
          Together, they achieved theoretical results regarding the behaviour of
          the BH (Benjamini-Hochberg) procedure, applied the FDR criterion to
          new challenges such as image analysis, and proposed new techniques for
          assessing the reproducibility of scientific results.
          <br />
          <br />
          At the start of the 21st century, scientific research underwent an
          industrial revolution. Experiments in genomics, proteomics, and brain
          research are now conducted with machines that produce a large amount
          of output. This output is then automatically processed, leading to
          many potential discoveries. With powerful computers and large
          databases now available, the FDR is increasingly being used in other
          fields of science such as agriculture, astronomy, behavioural
          sciences, and economics. The FDR procedure has found broad
          application, and the importance of FDR research is growing along with
          the complexity of the scientific questions posed.
          <br />
          <br />
          The FDR procedure is highly relevant in various branches of statistics
          and other fields of science, and many statisticians worldwide are
          conducting research into it. The three laureates have continued this
          work, both together and separately, as well as with others, to help
          the scientific community extract reliable information from complex
          data.
        </p>

        <hr></hr>

        <div className="dark-background">
          <h3>Jury 2024</h3>
          <p>David Hand (chair) - Imperial College</p>
          <br />
          <p>Lutgarde Buydens - Radboud University Nijmegen</p>
          <p>Probal Chaudhuri - Indian Statistical Institute</p>
          <p>Roger Koenker - University of Illinois</p>
          <p>Steve Marron - University of North Carolina</p>
          <p>Cynthia Rudin - Duke University</p>
          <p>Louise Ryan - University of Technology Sydney</p>
          <p>David Steinberg - Tel Aviv University</p>
          <p>Maria-Pia Victoria-Feser - University of Geneva</p>
          <p>Huixia Judy Wang - George Washington University</p>
        </div>

        <hr></hr>

        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <p>
            <a href={PressEnglish} target="_blank" rel="noreferrer" download>
              English press release
            </a>
          </p>
          <p>
            <a href={PressDutch} target="_blank" rel="noreferrer" download>
              Dutch press release
            </a>
          </p>
          <p>
            <a href={PressFrench} target="_blank" rel="noreferrer" download>
              French press release
            </a>
          </p>
          <p>
            <a href={PressGerman} target="_blank" rel="noreferrer" download>
              German press release
            </a>
          </p>
          <p>
            <a href={TechnicalDescription} target="_blank" rel="noreferrer" download>
              Technical description for statisticians
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
