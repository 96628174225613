import React from 'react'

export default function OriginOfTheName() {
    return (
        <p>The prize is named after its sponsor, the statistician Peter J. Rousseeuw.
        He studied statistics at the Free University 
        of Brussels and the ETH Zurich, Switzerland.
        He was a statistics professor at the Technical 
        University Delft, The Netherlands, the
        University of Fribourg, Switzerland, and 
        the University of Antwerp, Belgium.
        For a number of years Peter was a researcher 
        at a statistical arbitrage company in New York.
        He is currently affiliated with the University
        of Leuven (Belgium),
        and emeritus professor since October 2022. <br />
        
        Some information on Peter's work can be found at&nbsp;
        <a href="https://en.wikipedia.org/wiki/Peter_Rousseeuw" target="_blank" rel="noreferrer">Wikipedia</a>,&nbsp;
        <a href="https://scholar.google.com/citations?user=5LMM6rsAAAAJ&hl=en" target="_blank" rel="noreferrer">Google Scholar</a>, and&nbsp;
        <a href="https://wis.kuleuven.be/statdatascience/robust" target="_blank" rel="noreferrer">KU Leuven</a>.
        Peter advised many doctoral students, including
        Annick Leroy, Christophe Croux, Geert Molenberghs,
        Mia Hubert, Stefan Van Aelst,
        Tim Verdonck, and Jakob Raymaekers.
        </p>
    )
}
