import React from 'react'

export default function RulesOfThePrize() {
    return (
        <>
        <p className="boxes-intro">Care will be taken to ensure diversity among the awards.</p>
        <br/><br/>
            <div className="boxes-text boxes-text-light">
                <div>
                    <h3>I. Diversity of topics</h3>
                    <p>After an innovation is awarded in one of the five subfields
                        of statistics listed above, that subfield cannot be awarded again
                    in the next three cycles.</p>
                </div>

                <div>
                    <h3>II. International diversity</h3>
                    <p>Four geographical regions are considered. None of these regions can receive the award twice in a row:
                        <ul>
                            <li>Asia (China, India, Israel, Japan, Turkey,...)</li>
                            <li>Australia, Canada, Ireland, New Zealand, United Kingdom, United States</li>
                            <li>Continental Europe and Russia</li>
                            <li>The remainder of the world</li>
                        </ul>
                    </p>
                    <p>What matters is
                    where the majority of the work took place, irrespective of the nationalities of the participants.</p>
                    <br/>
                </div>

                <div>
                    <h3>III. No person can receive the award twice</h3>
                    <p>Should a topic be selected to which
                    a previously awarded person has contributed, that person shall not receive the award a
                    second time, but the other contributors will still receive the award.</p>
                </div>

                <div>
                    <h3>IV. Avoiding the appearance of a conflict of interest</h3>
                    <p>The first few awards cannot be
                    in Peter Rousseeuw’s research areas (clustering, robust statistics, anomaly detection) or go
                     to people working in Belgium or of Belgian nationality.</p>
                </div>
            </div>
        </>
    )
}
